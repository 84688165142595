import { useMutation } from '@apollo/client';
import Dropdown from 'antd/es/dropdown/dropdown';
import React, { useEffect, useState } from 'react';
// import { NavLink } from 'react-router-dom'
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import classes from './Header.module.scss';
// import AccountIcon from '@/icons/account-circle.svg'
import MenuItems from './MenuItems/MenuItems';
import { EDIT_USER_LANG } from '@/graphql/auth';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import ArrowLeftIcon from '@/icons/arrow-right.svg';
import ArrowDownIcon from '@/icons/arrows-down.svg';
import ExitIcon from '@/icons/exit.svg';
import LangIcon from '@/icons/lang.svg';
import MenuIcon from '@/icons/menu.svg';
import ThemeIcon from '@/icons/theme.svg';
import { languages } from '@/utils/constants';
import { getErrorMessage } from '@/utils/validateMessages';
const getLanguages = (handleChangeLang) => {
    const langList = languages.map((lang) => {
        return {
            key: lang.id,
            label: (React.createElement("div", { onClick: () => { handleChangeLang(lang.id); }, className: classes.langItem }, lang.label))
        };
    });
    return langList;
};
const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isMenu, setIsMenu] = useState(false);
    const intl = useIntl();
    const location = useLocation();
    const { firstName, lang } = useAppSelector((state) => state.auth.user);
    const { authLogoutRequest, changeLang } = useAppDispatch();
    const getCurrentLang = languages.find((langItem) => langItem.id === lang);
    const [editUser] = useMutation(EDIT_USER_LANG);
    const handleChangeLang = (lng) => {
        editUser({
            variables: { editUserInput: { lang: lng } }
        }).then(() => {
            changeLang(lng);
        }, (err) => { getErrorMessage(err, intl); });
    };
    useEffect(() => {
        setIsMenu(false);
    }, [location.pathname]);
    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY > 0;
            setIsScrolled(scrolled);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        setIsAnimating(true);
        const timeoutId = setTimeout(() => {
            setIsAnimating(false);
        }, 1100);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isScrolled]);
    const items = getLanguages(handleChangeLang);
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: `${isScrolled ? classes.scroll : ''} ${isAnimating ? classes.animation : ''} ${classes.header}` },
            React.createElement("div", { className: classes.box },
                React.createElement("div", { className: classes.userIcon, onClick: () => { setIsMenu(true); } },
                    React.createElement(MenuIcon, null)),
                React.createElement("div", { className: classes.theme },
                    React.createElement(ThemeIcon, null)))),
        React.createElement("div", { className: `${classes.nav} ${isMenu ? classes.navOpen : ''}` },
            React.createElement("div", { className: classes.navHead },
                React.createElement("div", { className: classes.navHeadTop },
                    React.createElement("div", { className: classes.navHeadTopLeft },
                        React.createElement("div", { className: classes.closeNav, onClick: () => { setIsMenu(false); } },
                            React.createElement(ArrowLeftIcon, null)),
                        React.createElement("div", { className: classes.balance },
                            React.createElement("div", { className: classes.balanceTitle }, intl.formatMessage({ id: 'common.balanceTitle', defaultMessage: 'Баланс' })),
                            React.createElement("div", { className: classes.balanceValue }, "\u20B40.00"))),
                    React.createElement("div", { className: classes.navHeadTopRight },
                        React.createElement("div", { className: classes.navTheme },
                            React.createElement(ThemeIcon, null)))),
                React.createElement("div", { className: classes.user },
                    intl.formatMessage({ id: 'common.goodDay', defaultMessage: 'Доброго дня' }),
                    firstName && `, ${firstName}`)),
            React.createElement("div", { className: classes.navBody },
                React.createElement(MenuItems, null),
                React.createElement("div", { className: classes.exit, onClick: () => authLogoutRequest() },
                    React.createElement("div", { className: classes.exitIcon },
                        React.createElement(ExitIcon, null)),
                    intl.formatMessage({ id: 'common.exit', defaultMessage: 'Вийти' })),
                React.createElement("div", { className: classes.lang },
                    React.createElement("div", { className: classes.langLeft },
                        React.createElement("div", { className: classes.langTitle },
                            React.createElement("div", { className: classes.langTitleIcon },
                                React.createElement(LangIcon, null)),
                            intl.formatMessage({ id: 'common.lang', defaultMessage: 'Мова' }))),
                    React.createElement("div", { className: classes.langRight },
                        React.createElement(Dropdown, { menu: { items }, trigger: ['click'], placement: "topRight" },
                            React.createElement("div", null,
                                React.createElement("div", { className: classes.langCurrent }, getCurrentLang === null || getCurrentLang === void 0 ? void 0 :
                                    getCurrentLang.label,
                                    React.createElement(ArrowDownIcon, { className: 'lang-arrow' }))))))))));
};
export default Header;
