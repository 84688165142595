import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import { getStepTitle } from './helpers/helpers';
import classes from './OfficeTrip.module.scss';
import ValueWithCurrency from '@/atoms/ValueWithCurrency/ValueWithCurrency';
import { GET_ACTIVE_TRIP, GET_DRIVER_STATUS, POLL_TRIP_STATE, TRIP_REQUEST_ACCEPT, TRIP_REQUEST_ARRIVE, TRIP_REQUEST_FINISH, TRIP_REQUEST_ONBOARD } from '@/graphql/officeTrips';
import usePageVisibility from '@/hooks/usePageVisibility';
import ArrowsIcon from '@/icons/arrows.svg';
import ClientIcon from '@/icons/client.svg';
import OfficeTripsBlackIcon from '@/icons/office-trips-black.svg';
import OfficeTripsIcon from '@/icons/office-trips.svg';
import PaymentIcon from '@/icons/payment.svg';
import { authSlice } from '@/redux/auth/reducer';
import { getCashType } from '@/utils/helpers';
import { setAccessToken } from '@/utils/localStorage';
import { getErrorMessage } from '@/utils/validateMessages';
const OfficeTrip = () => {
    var _a, _b, _c, _d, _e, _f;
    const [stopScroll, setStopScroll] = useState(false);
    const [currentTrip, setCurrentTrip] = useState(null);
    const [tripRequestData, setTripRequestData] = useState(false);
    const [status, setStatus] = useState('');
    const intl = useIntl();
    const dispatch = useDispatch();
    const visibilityTab = usePageVisibility();
    const { refetch: refetchDriverStatus } = useQuery(GET_DRIVER_STATUS, { skip: true });
    const { loading: activeTripLoading, error: activeTripError, data: activeTripData, refetch: refetchActiveTrip } = useQuery(GET_ACTIVE_TRIP);
    const [fetchTripRequest, { loading: tripRequestLoading }] = useLazyQuery(POLL_TRIP_STATE, {
        onCompleted: (data) => {
            var _a;
            const hasAssignedTrip = data === null || data === void 0 ? void 0 : data.pollTripState.hasAssignedTrip;
            setStatus((_a = data === null || data === void 0 ? void 0 : data.pollTripState) === null || _a === void 0 ? void 0 : _a.driverStatus);
            setTripRequestData(hasAssignedTrip);
        },
        onError: (error) => {
            var _a, _b;
            const errorCode = ((_b = (_a = error === null || error === void 0 ? void 0 : error.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions) === null || _b === void 0 ? void 0 : _b.code) || '';
            if (errorCode === 'BLOCKED') {
                setAccessToken(null);
                dispatch(authSlice.actions.authSetError('BLOCKED_AUTHORIZED'));
                dispatch(authSlice.actions.authSetCompanyId(''));
            }
        }
    });
    useEffect(() => {
        if (status) {
            void refetchDriverStatus();
        }
    }, [status]);
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!tripRequestLoading && !tripRequestData && !currentTrip && visibilityTab) {
                void fetchTripRequest();
            }
        }, 5000);
        return () => { clearInterval(intervalId); };
    }, [activeTripLoading, tripRequestData, fetchTripRequest, visibilityTab]);
    useEffect(() => {
        var _a, _b, _c;
        if (tripRequestData && !currentTrip) {
            void refetchActiveTrip();
        }
        if (activeTripData) {
            setCurrentTrip(((_a = activeTripData === null || activeTripData === void 0 ? void 0 : activeTripData.getActiveTrip) === null || _a === void 0 ? void 0 : _a.tripRequest) || null);
            setTripRequestData((_c = (_b = activeTripData === null || activeTripData === void 0 ? void 0 : activeTripData.getActiveTrip) === null || _b === void 0 ? void 0 : _b.tripRequest) === null || _c === void 0 ? void 0 : _c.id);
        }
    }, [activeTripData, tripRequestData, refetchActiveTrip]);
    const [tripRequestAccept] = useMutation(TRIP_REQUEST_ACCEPT, {
        refetchQueries: () => [GET_ACTIVE_TRIP]
    });
    const [tripRequestArrive] = useMutation(TRIP_REQUEST_ARRIVE, {
        refetchQueries: () => [GET_ACTIVE_TRIP]
    });
    const [tripRequestOnboard] = useMutation(TRIP_REQUEST_ONBOARD, {
        refetchQueries: () => [GET_ACTIVE_TRIP]
    });
    const [tripRequestFinish] = useMutation(TRIP_REQUEST_FINISH, {
        refetchQueries: () => [GET_ACTIVE_TRIP]
    });
    const nextStep = () => {
        const tripId = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.id;
        const tripStatus = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status;
        const variables = { variables: { tripRequestId: tripId } };
        if (tripStatus === 'ASSIGNED_TO_DRIVER') {
            tripRequestAccept(variables).then(() => {
                setStopScroll(false);
            }, (err) => {
                getErrorMessage(err, intl);
                setStopScroll(false);
            });
        }
        if (tripStatus === 'DRIVER_IN_ORDER_FREE') {
            tripRequestArrive(variables).then(() => {
                setStopScroll(false);
            }, (err) => {
                getErrorMessage(err, intl);
                setStopScroll(false);
            });
        }
        if (tripStatus === 'DRIVER_ARRIVED') {
            tripRequestOnboard(variables).then(() => {
                setStopScroll(false);
            }, (err) => {
                getErrorMessage(err, intl);
                setStopScroll(false);
            });
        }
        if (tripStatus === 'DRIVER_IN_ORDER_BUSY') {
            tripRequestFinish(variables).then(() => {
                setStopScroll(false);
                setTripRequestData(false);
            }, (err) => {
                getErrorMessage(err, intl);
                setStopScroll(false);
            });
        }
    };
    const isSwipe = () => {
        setStopScroll(true);
        setTimeout(() => {
            nextStep();
        }, 500);
    };
    const handlers = useSwipeable({
        onSwipedRight: () => { isSwipe(); }
    });
    if (activeTripLoading || tripRequestLoading || !currentTrip)
        return React.createElement(React.Fragment, null);
    if (activeTripError)
        return React.createElement("p", null,
            "Error in first query: ",
            activeTripError.message);
    return (React.createElement("div", { className: classes.main },
        React.createElement("div", { className: classes.overlay }),
        React.createElement("div", { className: classes.holder },
            React.createElement("div", { className: classes.box },
                React.createElement("div", { className: classes.head },
                    React.createElement("div", { className: classes.headTop },
                        React.createElement("div", { className: classes.headText },
                            React.createElement(OfficeTripsIcon, { width: 24, height: 24 }),
                            "Office Trip")),
                    (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'ASSIGNED_TO_DRIVER' && (React.createElement("div", { className: classes.title },
                        !(currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.createdByDriver) && intl.formatMessage({ id: 'officeTrips.title', defaultMessage: 'Менеджер пропонує вам поїздку типу Office trips' }),
                        (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.createdByDriver) && intl.formatMessage({ id: 'officeTrips.youCreated', defaultMessage: 'Вы створили поїздку типу Office trips' })))),
                React.createElement("div", { className: classes.info },
                    React.createElement("div", { className: classes.infoLeft },
                        React.createElement("div", { className: classes.numberOrder },
                            React.createElement(OfficeTripsBlackIcon, { width: 24, height: 24 }),
                            "\u2116", (_a = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.humanId) !== null && _a !== void 0 ? _a : '—'),
                        React.createElement("div", { className: classes.dateOrder }, (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.pickUpDateTime) ? dayjs(currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.pickUpDateTime).format('DD.MM.YYYY, HH:mm') : '—')),
                    React.createElement("div", { className: classes.infoRight },
                        React.createElement("div", { className: classes.cost },
                            React.createElement(ValueWithCurrency, { value: currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.cost, minimumFractionDigits: true, withZero: true })))),
                React.createElement("div", { className: classes.route },
                    React.createElement("div", { className: classes.routeList },
                        React.createElement("div", { className: classes.routeRowBox },
                            React.createElement("div", { className: classes.routeRow }, (_b = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.pickUpAddress) !== null && _b !== void 0 ? _b : '—')),
                        React.createElement("div", { className: classes.routeRowBox },
                            React.createElement("div", { className: classes.routeRow }, (_c = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.destinationAddress) !== null && _c !== void 0 ? _c : '—')))),
                React.createElement("div", { className: classes.client },
                    React.createElement("div", { className: classes.clientCell },
                        React.createElement("div", { className: classes.clientCellIcon },
                            React.createElement(PaymentIcon, { width: 24, height: 24 })),
                        React.createElement("div", { className: classes.clientCellBox },
                            React.createElement("div", { className: classes.clientCellTitle }, intl.formatMessage({ id: 'officeTrips.paymentType', defaultMessage: 'Тип оплати' })),
                            React.createElement("div", { className: classes.clientCellValue }, (_d = getCashType(currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.paymentType, intl)) !== null && _d !== void 0 ? _d : '—'))),
                    React.createElement("div", { className: classes.clientCell },
                        React.createElement("div", { className: classes.clientCellIcon },
                            React.createElement(ClientIcon, { width: 24, height: 24 })),
                        React.createElement("div", { className: classes.clientCellBox },
                            React.createElement("div", { className: classes.clientCellTitle }, intl.formatMessage({ id: 'officeTrips.clientName', defaultMessage: 'Клієнт' })),
                            React.createElement("div", { className: classes.clientCellValue }, (_e = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.clientName) !== null && _e !== void 0 ? _e : '—')))),
                React.createElement("div", { className: classes.comment },
                    React.createElement("div", { className: classes.commentBox },
                        React.createElement("div", { className: classes.commentTitle }, intl.formatMessage({ id: 'officeTrips.ManagerComment', defaultMessage: 'Коментар менеджера' })),
                        React.createElement("div", { className: classes.commentValue }, (_f = currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.comment) !== null && _f !== void 0 ? _f : '—')))),
            React.createElement("div", { className: classes.btnBox },
                (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) !== 'ASSIGNED_TO_DRIVER' && (React.createElement("div", { className: classes.stepsTitles },
                    React.createElement("div", { className: classes.stepsCurrent }, (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) ? getStepTitle(currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status, intl) : '—'),
                    React.createElement("div", { className: classes.stepsNext }, (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_FREE'
                        ? intl.formatMessage({ id: 'officeTrips.waitingForClient', defaultMessage: 'Очікую клієнта' })
                        : (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_ARRIVED'
                            ? intl.formatMessage({ id: 'officeTrips.startTrip', defaultMessage: 'Початок поїздки' })
                            : (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'DRIVER_IN_ORDER_BUSY'
                                ? intl.formatMessage({ id: 'officeTrips.completedTrip', defaultMessage: 'Поїздку завершено' })
                                : '—'))),
                React.createElement("div", Object.assign({ className: `${classes.swipeBtn} ${stopScroll ? classes.swipe : ''}` }, handlers),
                    React.createElement("div", { className: classes.swipeBtnIcon },
                        React.createElement(ArrowsIcon, { width: 24, height: 24 })),
                    (currentTrip === null || currentTrip === void 0 ? void 0 : currentTrip.status) === 'ASSIGNED_TO_DRIVER'
                        ? intl.formatMessage({ id: 'officeTrips.accept', defaultMessage: 'Змахніть, щоб прийняти' })
                        : intl.formatMessage({ id: 'officeTrips.changeStatus', defaultMessage: 'Змахніть, щоб змінити статус' }))))));
};
export default OfficeTrip;
