var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import classes from '../Auth.module.scss';
import { SIGN_UP_BY_INVITE } from '@/graphql/auth';
import useAppDispatch from '@/hooks/useAppDispatch';
import ArrowLeftIcon from '@/icons/arrow-right.svg';
import { routes } from '@/utils/constants';
import { setAccessToken } from '@/utils/localStorage';
import { getErrorMessage } from '@/utils/validateMessages';
const Registration = () => {
    const [form] = Form.useForm();
    const { inviteId } = useParams();
    const { authGetUser } = useAppDispatch();
    const intl = useIntl();
    const [signUpByInvite] = useMutation(SIGN_UP_BY_INVITE);
    const onFinish = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield signUpByInvite({
                variables: {
                    signUpByInviteInput: Object.assign({ invitationId: inviteId, password: values.password }, (navigator === null || navigator === void 0 ? void 0 : navigator.language) && { lang: navigator === null || navigator === void 0 ? void 0 : navigator.language })
                }
            });
            const jwtToken = (_a = data === null || data === void 0 ? void 0 : data.signUpByInvite) === null || _a === void 0 ? void 0 : _a.jwtToken;
            if (jwtToken) {
                setAccessToken(jwtToken);
                authGetUser();
            }
        }
        catch (error) {
            getErrorMessage(error, intl);
        }
    });
    return (React.createElement("div", { className: classes.auth },
        React.createElement("div", { className: classes.authHead },
            React.createElement(NavLink, { to: routes.auth.f, className: classes.BackBtn },
                React.createElement(ArrowLeftIcon, null)),
            React.createElement("div", { className: classes.title }, intl.formatMessage({ id: 'common.registration', defaultMessage: 'Регистрация' }))),
        React.createElement(Form, { autoComplete: "off", form: form, name: "register", onFinish: onFinish, scrollToFirstError: true },
            React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.createPassword', defaultMessage: 'Придумайте пароль' }), name: "password", rules: [
                    { required: true, message: intl.formatMessage({ id: 'error.enterPassword', defaultMessage: 'Please input your password!' }) },
                    { pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\w{6,}$/, message: intl.formatMessage({ id: 'error.password', defaultMessage: 'Password must contain at least one digit, one lowercase and one uppercase letter, and be at least 6 characters long.' }) }
                ], validateDebounce: 500 },
                React.createElement(Input.Password, { placeholder: intl.formatMessage({ id: 'common.password', defaultMessage: 'Пароль' }), autoComplete: 'new-password' })),
            React.createElement(Button, { type: "primary", htmlType: "submit", className: 'full high' }, intl.formatMessage({ id: 'common.register', defaultMessage: 'Зарегистрироваться' })),
            React.createElement("div", { className: classes.row },
                React.createElement("div", { className: classes.titleLink }, intl.formatMessage({ id: 'common.alreadyRegistered', defaultMessage: 'Уже зарегистрирован?' })),
                React.createElement(NavLink, { to: routes.login.f, className: classes.link }, intl.formatMessage({ id: 'common.signIn', defaultMessage: 'Увійти' }))))));
};
export default Registration;
