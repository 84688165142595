var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import useAppSelector from '@/hooks/useAppSelector';
import { getLocale } from '@/utils/helpers';
const loadMessages = (locale) => __awaiter(void 0, void 0, void 0, function* () {
    const messages = yield import(`./locales/${locale}`);
    return messages;
});
const LocaleProvider = ({ children }) => {
    const [localeMessages, setLocaleMessages] = useState({});
    const locale = getLocale(useAppSelector((state) => state.auth.user.lang));
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const data = yield loadMessages(locale);
                setLocaleMessages((data === null || data === void 0 ? void 0 : data.default) || {});
            }
            catch (error) { }
        });
        fetchData().catch(() => { });
    }, [locale]);
    const checkMessages = Object.keys(localeMessages).length;
    return (checkMessages
        ? (React.createElement(IntlProvider, { locale: locale, messages: localeMessages }, children))
        : React.createElement(React.Fragment, null));
};
export default LocaleProvider;
