var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classes from '../Auth.module.scss';
import Preloader from '@/atoms/Preloader/Preloader';
import { CHECK_RESET_PASSWORD, RESET_PASSWORD } from '@/graphql/auth';
import useAppDispatch from '@/hooks/useAppDispatch';
import ArrowLeftIcon from '@/icons/arrow-right.svg';
import { routes } from '@/utils/constants';
import { setAccessToken } from '@/utils/localStorage';
import { getErrorMessage } from '@/utils/validateMessages';
const ResetPassword = () => {
    const [form] = Form.useForm();
    const { resetPasswordId } = useParams();
    const { authGetUser } = useAppDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const { loading, error, data } = useQuery(CHECK_RESET_PASSWORD, {
        variables: {
            checkResetPasswordIdInput: {
                resetPasswordId
            }
        },
        skip: !resetPasswordId
    });
    useEffect(() => {
        var _a;
        if (((_a = data === null || data === void 0 ? void 0 : data.checkResetPasswordId) === null || _a === void 0 ? void 0 : _a.valid) === false) {
            navigate(routes.errorPage.f);
        }
    }, [data]);
    const [resetPassword] = useMutation(RESET_PASSWORD);
    const onFinish = (values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield resetPassword({
                variables: {
                    resetPasswordInput: {
                        resetPasswordId,
                        password: values.password
                    }
                }
            });
            const jwtToken = (_a = data === null || data === void 0 ? void 0 : data.resetPassword) === null || _a === void 0 ? void 0 : _a.jwtToken;
            if (jwtToken) {
                setAccessToken(jwtToken);
                authGetUser();
            }
        }
        catch (error) {
            getErrorMessage(error, intl);
        }
    });
    if (loading)
        return React.createElement(Preloader, { inner: true });
    if (error)
        return React.createElement(Alert, { message: intl.formatMessage({ id: 'error.dataLoading', defaultMessage: 'Ошибка при загрузке данных' }), type: "error" });
    return (React.createElement("div", { className: classes.auth },
        React.createElement("div", { className: classes.authHead },
            React.createElement(Link, { to: routes.auth.f, className: classes.BackBtn },
                React.createElement(ArrowLeftIcon, null)),
            React.createElement("div", { className: classes.title }, intl.formatMessage({ id: 'common.enterNewPassword', defaultMessage: 'Введите новый пароль' }))),
        React.createElement(Form, { autoComplete: "off", form: form, name: "newPassword", onFinish: onFinish, scrollToFirstError: true },
            React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.createPassword', defaultMessage: 'Придумайте пароль' }), name: "password", rules: [
                    { required: true, message: intl.formatMessage({ id: 'error.enterPassword', defaultMessage: 'Please input your password!' }) },
                    { pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\w{6,}$/, message: intl.formatMessage({ id: 'error.password', defaultMessage: 'Password must contain at least one digit, one lowercase and one uppercase letter, and be at least 6 characters long.' }) }
                ], validateDebounce: 500 },
                React.createElement(Input.Password, { placeholder: intl.formatMessage({ id: 'common.password', defaultMessage: 'Пароль' }), autoComplete: 'new-password' })),
            React.createElement(Button, { type: "primary", htmlType: "submit", className: 'full high' }, intl.formatMessage({ id: 'common.changePassword', defaultMessage: 'Изменить пароль' })))));
};
export default ResetPassword;
