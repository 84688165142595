var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classes from '../Auth.module.scss';
import { SING_IN } from '@/graphql/auth';
import useAppDispatch from '@/hooks/useAppDispatch';
import useAppSelector from '@/hooks/useAppSelector';
import ArrowLeftIcon from '@/icons/arrow-right.svg';
import { routes } from '@/utils/constants';
import { setAccessToken } from '@/utils/localStorage';
import { getErrorMessage } from '@/utils/validateMessages';
const Login = () => {
    const { authGetUser } = useAppDispatch();
    const { error } = useAppSelector((state) => state.auth);
    const isBlocked = error === 'BLOCKED';
    const intl = useIntl();
    const [signInMutation] = useMutation(SING_IN);
    const onLoginCompleted = (res) => {
        var _a, _b;
        const jwtToken = (_b = (_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.signIn) === null || _b === void 0 ? void 0 : _b.jwtToken;
        if (jwtToken) {
            setAccessToken(jwtToken);
            authGetUser();
        }
    };
    const onLoginError = (err) => {
        getErrorMessage(err, intl);
    };
    const onFinish = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { phone, password } = values;
        signInMutation({
            variables: { signInInput: { phone, password } }
        }).then((res) => { onLoginCompleted(res); }, (err) => { onLoginError(err); });
    });
    return (React.createElement("div", { className: classes.auth },
        React.createElement("div", { className: classes.authHead },
            React.createElement(NavLink, { to: routes.auth.f, className: classes.BackBtn },
                React.createElement(ArrowLeftIcon, null)),
            React.createElement("div", { className: classes.title }, intl.formatMessage({ id: 'common.authorization', defaultMessage: 'Авторизация' }))),
        isBlocked && React.createElement("div", { className: classes.blocked }, intl.formatMessage({ id: 'error.blocked', defaultMessage: 'Доступ в кабинет водителя ограничен, так как Ваш аккаунт находится в статусе "Уволен", "Черный список" или "Заблокирован"' })),
        React.createElement(Form, { autoComplete: "off", name: "login", onFinish: onFinish, scrollToFirstError: true },
            React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.phone', defaultMessage: 'Телефон' }), name: "phone", normalize: (value) => (value ? value === null || value === void 0 ? void 0 : value.replace(/[^0-9+]/g, '') : value), rules: [{ required: true, message: intl.formatMessage({ id: 'error.enterPhone', defaultMessage: 'Please input your phone!' }) }] },
                React.createElement(Input, { placeholder: intl.formatMessage({ id: 'common.phone', defaultMessage: 'Телефон' }) })),
            React.createElement(Form.Item, { label: intl.formatMessage({ id: 'common.password', defaultMessage: 'Пароль' }), name: "password", rules: [{ required: true, message: intl.formatMessage({ id: 'error.enterPassword', defaultMessage: 'Please input your password!' }) }] },
                React.createElement(Input.Password, { placeholder: intl.formatMessage({ id: 'common.password', defaultMessage: 'Пароль' }) })),
            React.createElement(Button, { type: "primary", htmlType: "submit", className: 'full high' }, intl.formatMessage({ id: 'common.signIn', defaultMessage: 'Войти' })))));
};
export default Login;
